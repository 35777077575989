const size = (unit, multiplier) => `calc(${multiplier} * ${unit})`

export default {
  colors: {
    primary: 'black',
    background: 'white',
  },
  zIndex: {
    header: 50,
  },
  dimensions: {
    container: {
      padding: unit => size(unit, 0.5),
    },
    header: {
      height: unit => size(unit, 2),
    },
    content: {
      paddingTop: unit => size(unit, 1.5),
    },
  },
  breakpoints: {
    mobile: '@media only screen and (max-device-width: 480px)',
    tablet: '@media only screen and (max-device-width: 900px)',
    aboveTablet: '@media only screen and (min-device-width: 900px)',
    aboveMobile: '@media only screen and (min-device-width: 481px)',
  },
}
