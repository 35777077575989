import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import PropTypes from 'prop-types'
import { ClassNames } from '@emotion/core'
import { rhythm } from '../utils/typography'
import theme from '../utils/theme'

const styles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: rhythm(0.5),
    top: rhythm(0.5),
  },
  bmBurgerBars: {
    background: '#373a47',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: theme.colors.background,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#b8b7ad',
    padding: '0.8em',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

const ResponsiveMenu = ({ children }) => (
  <ClassNames>
    {({ cx, css }) => (
      <Menu
        burgerButtonClassName={cx(css`
          ${theme.breakpoints.aboveTablet} {
            display: none;
          }
        `)}
        right
        styles={styles}
        width={'100%'}
        css={css`
          a {
            margin-bottom: ${rhythm(0.5)};
          }
        `}
      >
        {children}
      </Menu>
    )}
  </ClassNames>
)

ResponsiveMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ResponsiveMenu
