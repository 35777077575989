import Typography from 'typography'
import Sutro from 'typography-theme-sutro'
import theme from './theme'

Sutro.googleFonts = [
  {
    name: 'Merriweather',
    styles: ['700'],
  },
  {
    name: 'Raleway',
    styles: ['300', '300i', '700', '700i'],
  },
]

Sutro.headerFontFamily = ['Merriweather', 'Georgia', 'serif']
Sutro.bodyFontFamily = ['Raleway', 'sans-serif']

Sutro.overrideThemeStyles = ({ rhythm }) => ({
  a: {
    color: theme.colors.primary,
  },
  '.blog-content a:not(.clear-link), .about a:not(.clear-link)': {
    backgroundImage: `linear-gradient(to right, ${theme.colors.primary} 100%, ${theme.colors.primary} 0)`,
    backgroundPosition: '0 calc(1em + 1px)',
    backgroundSize: '1px 1px',
    backgroundRepeat: 'repeat-x',
  },
  '.blog-content ol, .blog-content ul': {
    paddingLeft: '1rem',
  },
  p: {
    marginBottom: rhythm(0.5),
  },
  ul: {
    paddingLeft: rhythm(1.5),
  },
  'h1,h2,h3,h4,h5,h6': {
    marginTop: rhythm(1.5),
  },
})

const typography = new Typography(Sutro)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
