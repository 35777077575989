import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'emotion-theming'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import theme from '../utils/theme'
import { rhythm } from '../utils/typography'
import Header from './Header'

const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const PageContainer = styled.div`
  padding-top: ${({ theme }) => theme.dimensions.header.height(rhythm())};
`

const PageContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${rhythm(24)};
  padding: ${rhythm(1.5)} ${rhythm(3 / 4)};
`

class Layout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { children, className } = this.props

    return (
      <StaticQuery
        query={query}
        render={data => (
          <ThemeProvider theme={theme}>
            <PageContainer>
              <Header title={data.site.siteMetadata.title} />
              <PageContent className={className}>{children}</PageContent>
            </PageContainer>
          </ThemeProvider>
        )}
      />
    )
  }
}

export default Layout
