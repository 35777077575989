import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { graphql, Link, StaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { rhythm } from '../utils/typography'
import ResponsiveMenu from './ResponsiveMenu'

const Header = styled.header`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.header};
  top: 0;
  right: 0;
  left: 0;
  height: ${({ theme }) => theme.dimensions.header.height(rhythm())};
  // box-shadow: 0px 5px 30px -15px rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.background};
`

const LogoContainer = styled.div`
  position: absolute;
  left: ${rhythm(0.5)};
  top: ${rhythm(0.25)};
`

const Logo = styled.img`
  width: 40px;
  margin: 0;
`

const Menu = styled.ul`
  margin: 0 auto;
  max-width: ${rhythm(24)};
  padding-left: 0;
`

const MenuItem = styled.li`
  display: inline-block;
  text-decoration: none;
  font-weight: bold;
  padding: ${rhythm(0.5)};
  margin: 0;
`

const SocialLinks = styled.div`
  position: absolute;
  right: ${rhythm(0.5)};
  top: 50%;
  height: 26px;
  transform: translateY(-50%);
`

const SocialAnchor = styled.a`
  margin-left: ${rhythm(0.5)};
  width: auto;
  height: ${rhythm(0.75)};

  ${({ theme }) => `${theme.breakpoints.mobile} {
    margin-left: 0;
  }`}
`

const SocialImage = styled.img`
  height: inherit;
  margin: 0;
`

const LinkNoHover = styled(Link)`
  &:hover,
  &:active {
    text-decoration: none;
  }
`

const hideOnTablet = theme => css`
  ${theme.breakpoints.tablet} {
    display: none;
  }
`

const SocialLink = ({ link, imageUrl, alt }) => (
  <SocialAnchor href={link} target="_blank" rel="noopener">
    <SocialImage src={imageUrl} alt={alt} />
  </SocialAnchor>
)

function HeaderComp() {
  return (
    <StaticQuery
      query={headerQuery}
      render={data => {
        const { github, twitter, linkedin } = data.site.siteMetadata.social
        return (
          <Header>
            <LogoContainer>
              <LinkNoHover to="/">
                <Logo
                  src={data.logo.publicURL}
                  title="Jonas Pauthier"
                  alt="site logo"
                />
              </LinkNoHover>
            </LogoContainer>
            <nav css={hideOnTablet}>
              <Menu>
                <MenuItem>
                  <Link to="/">Blog</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/about">About</Link>
                </MenuItem>
              </Menu>
            </nav>

            <SocialLinks css={hideOnTablet}>
              <SocialLink
                link={`https://github.com/${github}`}
                imageUrl={data.github.publicURL}
                alt="github logo"
              />
              <SocialLink
                link={`https://twitter.com/${twitter}`}
                imageUrl={data.twitter.publicURL}
                alt="twitter logo"
              />
              <SocialLink
                link={`https://fr.linkedin.com/in/${linkedin}`}
                imageUrl={data.linkedin.publicURL}
                alt="linkedin logo"
              />
            </SocialLinks>

            <ResponsiveMenu>
              <Link to="/">Blog</Link>
              <Link to="/about">About</Link>
              <SocialLink
                link={`https://github.com/${github}`}
                imageUrl={data.github.publicURL}
                alt="github logo"
              />
              <SocialLink
                link={`https://twitter.com/${twitter}`}
                imageUrl={data.twitter.publicURL}
                alt="twitter logo"
              />
              <SocialLink
                link={`https://fr.linkedin.com/in/${linkedin}`}
                imageUrl={data.linkedin.publicURL}
                alt="linkedin logo"
              />
            </ResponsiveMenu>
          </Header>
        )
      }}
    />
  )
}

HeaderComp.propTypes = {
  title: PropTypes.string.isRequired,
}

const headerQuery = graphql`
  query headerQuery {
    site {
      siteMetadata {
        author
        social {
          twitter
          github
          linkedin
        }
      }
    }

    github: file(base: { eq: "github.svg" }) {
      publicURL
    }

    twitter: file(base: { eq: "twitter.svg" }) {
      publicURL
    }

    linkedin: file(base: { eq: "linkedin.png" }) {
      publicURL
    }

    logo: file(base: { eq: "logo.svg" }) {
      publicURL
    }
  }
`

export default HeaderComp
